@mixin absolute($args) {
  @include position(absolute, $args);
}

@mixin fixed($args) {
  @include position(fixed, $args);
}

@mixin relative($args) {
  @include position(relative, $args);
}

@mixin position($position, $args) {
  position: $position;
  // list of offsets to check for in $args
  $offsets: top right bottom left;

  // loop through $offsets
  @each $o in $offsets {
    // if current offset found in $args
    // assigns its index to $i or 'false' if not found
    $i: index($args, $o);

    @if $i
      // offset in $args?
      and
      $i +
      1 <=
      length($args)
      // offset value in range?
      and
      type-of(nth($args, $i + 1)) ==
      number
    {
      #{$o}: nth($args, $i + 1);
    }
  }
}

@mixin align-vertically($position: absolute) {
  // assumes position absolute if none passed
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin align-horizontally() {
  @include absolute(left 50%);
  transform: translateX(-50%);
}

@mixin align-both-directions() {
  @include absolute(top 50% left 50%);
  transform: translate(-50%, -50%);
}

@mixin flex-center-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin user-select($args) {
  webkit-user-select: $args;
  -moz-user-select: $args;
  -ms-user-select: $args;
  user-select: $args;
}

@mixin styled-scrollbar() {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7b7b7b;
  }
}

@mixin animation-fadeIn($speed: 250ms, $timingFunction: ease) {
  animation: fadeIn $speed $timingFunction;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

// 1366px is based on current largest iPad width
// matches value in BrowserService
$compactBreakpoint: 1366px;

@mixin desktopOrLargerScreen() {
  @media screen and (min-width: $compactBreakpoint) {
    @content;
  }
}

@mixin compactScreen() {
  @media screen and (max-width: $compactBreakpoint) {
    @content;
  }
}

@mixin compactPortraitOnly() {
  @media screen and (max-width: $compactBreakpoint) and (orientation: portrait) {
    @content;
  }
}

@mixin compactLandscapeOnly() {
  @media screen and (max-width: $compactBreakpoint) and (orientation: landscape) {
    @content;
  }
}

@mixin compactSmallPortraitOnly() {
  @media screen and (max-width: 415px) and (orientation: portrait) {
    @content;
  }
}

@mixin compactSmallLandscapeOnly() {
  @media screen and (max-width: 825px) and (orientation: landscape) {
    @content;
  }
}
//  Accommodate very small Smart phones like the Galaxy Fold.
@mixin compactExtraSmall() {
  @media screen and (max-width: 310px) {
    @content;
  }
}
