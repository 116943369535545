/* Ideally we stick to the convention where every background has a constrasting foreground color.
   Then thru out code you can always feel secure just matching them up by the number

   For Transparency colors: the ThemeService will automatically create high, medium, and low values for EVERY color setting with a UIName
   Check the ColorTransparencyModifiers config for more detail
*/
:root {
  --primaryfont: Roboto;

  --background: #fff;
  --foreground: #3a3a3a;
  --icon-foreground: #000;
  --border: #696969;
  --loading-cover-layer-background-color: #c2c2c2;

  --title-background: var(--background);
  --title-foreground: var(--foreground);

  --selected-option-icon-background: #000;
  --selected-option-icon-foreground: #fff;
  --category-header-selected-option-icon: var(--icon-foreground);

  --dropdown-background: #ececec;
  --dropdown-foreground: var(--foreground);
  --dropdown-item-background-hover: rgba(0, 0, 0, 0.1);
  --dropdown-item-icon-color: var(--icon-foreground);

  --category-dropdown-background: var(--dropdown-background);
  --category-dropdown-foreground: var(--dropdown-foreground);

  --thumbnail-menu-background: #393939;
  --thumbnail-menu-foreground: #fff;

  --hotspot-icon-color: #000;
  --minimap-hotspot-icon-color: #000;
  --minimap-hotspot-radar-cone-color: #000;
  --mini-map-width: 15vw;
  --mini-map-mobile-width: 15vw;
  --mini-map-btn-background: #222;
  --mini-map-btn-foreground: white;

  --loading-animation-background: var(--background);
  --loading-animation-foreground: var(--foreground);
  --secondary-loading-animation-background: var(--loading-animation-background);
  --secondary-loading-animation-foreground: var(--loading-animation-foreground);

  --border-radius-modals: 3px;

  --modal-background: #fff;
  --modal-foreground: #000;

  /****************** Below are copypasta from myscp and should eventually be ripped out I think ******************/

  /* Special cases in theme service */

  --headerfont: Roboto;
  --headerborder: #29abe2;
  --headerfontuppercase: none;

  --blur-level: 1px;

  /* Regular color settings with alpha modifiers */
  --background1: #2f323a;
  --foreground1: #fff;

  --background2: #f2f2f2;
  --foreground2: #2f323a;

  --background3: #575a60;
  --foreground3: #ffffff;

  --background4: #29abe2;
  --foreground4: #ffffff;

  --background5: #999999;
  --foreground5: #ffffff;

  --background6: #ffffff;
  --foreground6: #2f323a;

  --background7: #304b68;
  --foreground7: #ffffff;

  --favoritesactive: #f3b544;
  --favoritesinactive: #999999;
  --favoritesforeground: #fff; /* no setting */

  --fplistbackground: #f2f2f2;
  --ifpbackground: #f2f2f2;

  --inventory-home-color: var(--background4);
  --inventory-home-foreground: var(--foreground1); /* no setting */

  --icon-background: var(--background4);
  /* --icon-foreground: var(--foreground4); */

  --global-active-background: var(--background4);
  --global-active-foreground: var(--foreground4);

  --navigationbackground: var(--background1); /* primary 1 */
  --navigationtilebackground: var(--background3); /* general 4 */
  --navigationtileforeground: var(--foreground3); /* general 1 */
  --navigationselected: var(--background4); /* primary 2 */
  --navigationselectedforeground: var(--foreground4); /* no setting */
  --navigationselectedforeground-alpha-low: var(--foreground4-alpha-low);
  --navigationselectedforeground-alpha-medium: var(--foreground4-alpha-medium);
  --navigationselectedforeground-alpha-high: var(--foreground4-alpha-high);

  --quick-nav-background: var(--navigationbackground);
  --quick-nav-foreground: var(--foreground1); /* general 1 */
  --quick-nav-selected: var(--navigationselected);
  --quick-nav-selected-foreground: var(--navigationselectedforeground); /* no setting */
  --quick-nav-selected-foreground-alpha-low: var(--navigationselectedforeground-alpha-low);
  --quick-nav-selected-foreground-alpha-medium: var(--navigationselectedforeground-alpha-medium);
  --quick-nav-selected-foreground-alpha-high: var(--navigationselectedforeground-alpha-high);

  --brochureheaderfooterbackground: var(--background1);
  --brochureheaderfooterforeground: var(--foreground1);

  --standardbuttonbackground: var(--background1);
  --standardbuttonforeground: var(--foreground1);
  --prioritybuttonbackground: var(--background7);
  --prioritybuttonforeground: var(--foreground7);

  --border-radius-accordions: 0;
  --border-radius-buttons: 0;
  --border-radius-cards: 0;
  --border-radius-quick-nav: 0;

  --pin-pad-title-bkg: var(--background3);
  --pin-pad-title-fore: var(--foreground3);
  --pin-pad-body-bkg: var(--background1);
  --pin-pad-body-fore: var(--foreground1);
  --pin-pad-button-bkg: var(--background7);
  --pin-pad-button-fore: var(--foreground7);

  --gallery-ctrl-bkg: var(--background1);
  --gallery-ctrl-fullscreen-bkg: var(--background3);
  --gallery-button-bkg: var(--standardbuttonbackground);
  --gallery-button-foreground: var(--standardbuttonforeground);

  /* Hardcoded (no settings) */
  --slide-out-menu-standard-width: 25vw;
  --current-slide-out-menu-width: 25vw; /* value is dynamic */
  --transparent-gray: #44444499;
  --bottom-nav-height: 60px;
  --box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);

  --print-page-height: 11in; /* 11in is standard height of "letter" paper size */
  --print-page-width: 100%;

  --floating-page-title-height: 3rem;
}
