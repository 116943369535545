.pulse-scale {
  --scaleStart: 1;
  --scaleEnd: 1.2;

  // animation must be applied to child since parent is using transform to position hotpost over image
  & > * {
    animation: pulseScaleFrame 2s infinite;
    animation-direction: alternate;
  }
}

.pulse-scale-hover {
  & > * {
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    // animation must be applied to child since parent is using transform to position hotpost over image

    & > * {
      transition: all 0.5s ease-in-out;
      --scaleEnd: 1.3;
      transform: scale(1.3);
    }
  }
}

@keyframes pulseScaleFrame {
  0% {
    transform: scale(var(--scaleStart));
  }
  100% {
    transform: scale(var(--scaleEnd));
  }
}

.pulse-opacity {
  --opacityStart: 1;
  --opacityEnd: 0;
  animation: pulseOpacityFrame 1.5s infinite;
  animation-direction: alternate;
}

@keyframes pulseOpacityFrame {
  0% {
    opacity: var(--opacityStart);
  }
  100% {
    opacity: var(--opacityEnd);
  }
}

@keyframes slideInAngle {
  0% {
    transform: rotate3d(3, 13, -1, -35deg) translateX(90%);
    transform-origin: bottom right;
  }

  20% {
    transform: rotate3d(3, 13, -1, -35deg) translateX(40%);
    transform-origin: bottom right;
  }

  40% {
    transform: rotate3d(3, 13, -1, -35deg) translateX(0%);
    transform-origin: bottom right;
  }

  60% {
    transform: rotate3d(3, 13, -1, -35deg) translateX(0%);
    transform-origin: bottom right;
  }

  80% {
    transform: rotate3d(3, 13, -1, -35deg) translateX(0%);
    transform-origin: bottom right;
  }

  100% {
    // transform: rotate3d(3, 13, -1, -35deg) translateX(0%);
    // transform-origin: bottom right;

    // transform: rotate3d(0, 17, -1, -14deg) translateX(0%);
    // transform-origin: bottom right;

    // transform: rotate3d(0) translate(0);
    // transform-origin: bottom right;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
