@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$text: (
  100: var(--foreground),
  500: var(--foreground),
  700: var(--foreground),
  contrast: ()
);

// icon is only for the required astrisk in the share form so not using --icon-foreground for now
$icon: (
  100: var(--foreground),
  500: var(--foreground),
  700: var(--foreground),
  contrast: ()
);
$app-primary: mat.define-palette($text);
$app-accent: mat.define-palette($icon);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    )
  )
);

$background-color: var(--background);

$background: map-get($app-theme, background);
$background: map_merge(
  $background,
  (
    dialog: $background-color
  )
);

$foreground-color: var(--foreground);

$foreground: map-get($app-theme, foreground);
$foreground: map_merge(
  $foreground,
  (
    text: $foreground-color,
    disabled-button: $foreground-color,
    secondary-text: $foreground-color,
    divider: $foreground-color
  )
);

$color: map-get($app-theme, color);
$color: map_merge(
  $color,
  (
    background: $background,
    foreground: $foreground
  )
);
$app-theme: map_merge(
  $app-theme,
  (
    color: $color
  )
);

$custom-typography: mat.define-typography-config(
  $font-family: var(--primaryfont),
  $button: mat.define-typography-level(16px, 24px, 500)
);

@include mat.all-component-typographies($custom-typography);

@include mat.all-component-themes($app-theme);

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button.mat-primary.mat-button-disabled {
  opacity: 0.25;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container.mat-mdc-dialog-container {
  border-radius: var(--border-radius-modals);
}

mat-spinner.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: var(--secondary-loading-animation-foreground);
}
