@import 'master';
@import 'material-theme.scss';
@import 'animations.scss';

body {
  font-family: var(--primaryfont), sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
}

/* TODO - decide this or mixin styled-scrollbar */
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.flex {
  display: flex;

  &.row {
    flex-direction: row;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }
}

.blocking-overlay-backdrop {
  background-color: var(--loading-cover-layer-background-color);
  pointer-events: auto !important;
  backdrop-filter: blur(var(--blur-level));
}

.quick-nav {
  display: flex;
  @include fixed(bottom 20px left 20px);
  background: transparent linear-gradient(90deg, #005daae6 0%, #005daa00 100%) 0% 0%;
  z-index: 10;
  border-radius: 0px 0px 0px 12px;
  padding-right: 3rem;

  icon {
    cursor: pointer;
    padding: 10px;

    svg {
      width: 25px;
      height: 25px;
      pointer-events: none;
      fill: white;
    }
  }

  .favorite-count {
    position: relative;
    padding: 6px;
    @include flex-center-center();

    icon {
      padding: 0;
    }

    .count {
      opacity: 0;
      @include absolute(top 2px right -3px);
      border-radius: 50%;
      width: 18px;
      height: 18px;
      @include flex-center-center();
      background-color: #3db973;
      color: #fff;
      font-size: 11px;
      transition: opacity 200ms ease;

      &.show {
        opacity: 1;
      }
    }
  }
}

// these classes are needed for hotspots because krpano will apply inline values that need overriden
@for $i from 1 through 2000 {
  .height-#{$i} {
    height: #{$i}px !important;
  }
}

@for $i from 1 through 2000 {
  .width-#{$i} {
    width: #{$i}px !important;
  }
}

@for $i from 1 through 500 {
  .min-height-vh-#{$i} {
    min-height: #{$i}vh !important;
  }
}

@for $i from 1 through 100 {
  .width-vw-#{$i} {
    width: #{$i}vw !important;
  }
}
